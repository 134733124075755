.custom-calendar-container {
  max-width: 100%;
  margin: 0 auto;
  padding: 0 1rem; /* Add padding for better spacing on mobile */
}

.custom-calendar {
  width: 100%;
  max-width: 100%;
  background: white;
  border: none;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  font-family: Arial, sans-serif;
  font-size: 14px; /* Base font size for mobile */
}

.react-calendar__tile {
  padding: 0.75em 0.5em; /* Reduce padding for better fit on mobile */
  height: auto; /* Allow height to adjust based on content */
  text-align: center; /* Center align text for better appearance */
}

.react-calendar__month-view__days__day--weekend {
  color: #d10000;
}

.react-calendar__tile--active {
  background: #006edc;
  color: white;
}

.react-calendar__tile--now {
  background: #ffff76;
}

.react-calendar__navigation button {
  min-width: 44px;
  background: none;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #e6e6e6;
}

.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
}

@media (min-width: 640px) {
  .custom-calendar {
    font-size: 16px; /* Larger font size for bigger screens */
  }
}

.react-calendar__navigation button {
  min-width: 44px;
  background: none;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #e6e6e6;
}

.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
}

.custom-calendar {
  width: 100%;
  max-width: 100%;
  font-size: 14px; /* Adjust font size for better readability */
}

@media (min-width: 640px) {
  .custom-calendar {
    font-size: 16px; /* Larger font size for bigger screens */
  }
}